export const AG_AC_TOKEN = 'adsGraderAccessToken'
export const USER = 'user'
export const LS_KEY = 'np_ads_grader:session'
export const UPDATE_USER = 'updateUser'
export const UPDATE_CUSTOMER = 'updateCustomer'
export const UPDATE_REPORT = 'updateReport'
export const LOGOUT_USER = 'logoutUser'
export const UPDATE_ROLE = 'role'
export const UPDATE_SAMPLE_REPORT = 'sampleReport'
export const UPDATE_REPORT_DASHBOARD = 'reportDashboardReducer'
export const UPDATE_ERROR_CUSTOMER = 'errorCustomer'
export const UPDATE_GENERATE_REPORT = 'generateReport'
export const UPDATE_CURRENT_REPORT = 'currentReport'
export const UPDATE_LEARNING_SEARCH_STRING = 'learningCenterSearch'
export const UPDATE_LEARNING_SELECTED_VALUE = 'learningCenterSelect'
export const SELECTED_ID = 'selectedId'
export const MANAGE_USERS_TAB = 'manageUsersTab'
export const ACCOUNT_TAB = 'accountTab'
export const PLATFORM_INTEGRATIONS = 'platformIntegrations'
export const ADS_PERFORMANCE_GRADE = 'adsperformancegrade'
export const PERFORMANCE_SECTION = 'performanceSection'
export const COST_EVALUATION_SECTION = 'costEvaluationSection'
export const WASTED_SPEND_SECTION = 'wastedSpendSection'
export const AD_RELEVANCE_SECTION = 'adRelevanceSection'
export const QUALITY_SCORE_SECTION = 'qualityScoreSection'
export const CLICK_THROUGH_RATE_SECTION = 'clickThroughRateSection'
export const KEYWORDS_MATCH_TYPE_SECTION = 'keywordsMatchTypeSection'
export const IMPRESSION_SHARE_SECTION = 'impressionShareSection'
export const GOOGLE_ADS_BEST_PRACTICES_SECTION = 'googleAdsBestPracticesSection'
export const SHARE_WITH_TEAM_SECTION = 'shareWithTeamSection'
export const ADS_BEDGET_COST_CACULATOR = 'adsBudgetCostCalculator'
export const AG_ALERTS = 'agAlert'
export const ADS_SEARCH_COMPANY = 'adsPreviewSearchCompany'
export const ADS_SEARCH_KEYWORD = 'adsPreviewSearchKeyword'
export const ADS_START_DATE = 'adsPreviewStartDate'
export const ADS_END_DATE = 'adsPreviewEndDate'
export const ADS_LOCATION = 'adsPreviewLocation'
export const ADS_CHANNEL = 'adsPreviewChannel'
export const ADS_FORMAT = 'adsPreviewFormat'
export const ADS_DATA = 'adsPreviewData'
export const ADS_PAGINATION_DATA = 'adsPaginationData'
export const EMPTY_ADS_PREVIEW_DATA = 'emptyAdsPreviewData'
export const EMPTY_ADS_PAGINATION_DATA = 'emptyPaginationData'
export const ADS_LOADER = 'adsPreviewLoader'
export const ADS_HOME_STATE = 'adsHomeState'
export const ADS_NO_DATA = 'adsNoData'
export const ADS_PAGINATION = 'adsPagination'
export const ADS_MODAL_PAGINATION = 'adsModalPagination'
export const LEARNING_CENTER = 'learningCenter'
export const LEARNING_CENTER_MESSAGE_FROM_MODAL = 'learningCenterMessageFromModal'
export const LEARNING_CENTER_API_DATA = 'learningCenterApiData'
export const LEAD_DATA = 'leadData'
export const UPDATE_ACTIVE_BUTTON_STATE = 'update_active_button_state'
export const FILTERED_DATA_LENGTH = 'filtered_data_length'
export const HAS_MORE = 'has_more'
export const PAGE_COUNT = 'page_count'
export const PAGE_COUNT_RESET = 'page_count_reset'
export const SPINNER = 'spinner'
export const CLEANED_DATA_COUNT = 'cleaned_data_count'
export const CURRENT_REPORT_ID = 'current_report_id'
export const MORE_DATA_LOADER = 'moreDataLoader'
export const META_ADS_PAGINATION_DATA = 'meta_ads_pagination_data'
export const WEEKLY_REPORT = 'weeklyReport'
export const REPORT_DIFFERENCE = ' reportDifference'
export const SINGLE_REPORT = 'singleReport'
export const REPORT_INDEX = 'reportIndex'
export const ALL_REPORTS = 'allReports'
export const PAGINATED_REPORTS = 'paginatedreports'
export const PAGINATED_NEXT = 'paginatedNext'
export const REPORT_CURRENT_ID = 'report_current_id'
export const EXTRA_INSIGHTS_CLICK = 'extra_insights_click'
export const EXTRA_INSIGHTS_DATA = 'extra_insights_data'
export const ADD_BOARD = 'add_board'
export const SET_BOARDS = 'set_boards'
export const SAVE_AD_TO_BOARD = 'save_ad_to_board'
export const RENAME_BOARD = 'rename_board'
export const DELETE_BOARD = 'delete_board'
export const BOARDS_SEARCH_KEYWORD = 'boards_search_keyword'
export const CLEAR_STATE = 'clear_state'
export const TEMP_COUNT = 'temp_count'
export const BOARD_DETAIL_DATA = 'board_detail_data'
export const ADS_DATA_FOR_NO_DATA_FOUND = 'ads_data_fot_no_data_found'
export const BOARD_SEARCH = 'board_search'
export const BOARDS_LIST = 'boards_list'
export const BOARDS_LIST_COUNT = 'boards_list_count'
export const TAGS = 'tags'
export const FILTERED_TAGS = 'filtered_tags'
export const CLEAR_BRANDS_FLITER = 'clear_brands_filter'
export const BOARD_LIST = 'board_list'
export const BOARDS_TAG_FILTER = 'boards_tag_filter'
export const SAVED_PAGE_ALL_DATA = 'saved_page_all_data'
export const SAVED_PAGE_FILTER_ALL_DATA = 'saved_page_filter_all_data'
export const SAVED_PAGE_UNIQUE_TAGS = 'saved_page_unique_tags'
export const API_DATA_BOARDS_LIST = 'api_data_baords_list'
export const SCORE_AND_INDUSTRY_LOADER = 'score_and_industry_loader'
export const SCORE_AND_INDUSTRY = 'score_and_industry'
export const RELATED_QUERIES = 'related_queries'
export const RELATED_QUERIES_LOADER = 'related_queries_loader'
export const SHARED_BOARDS = 'shared_boards'
export const SHARED_AND_PRIVATE_BOARDS_COUNT = 'shared_and_private_boards_count'
export const ACTIVE_KEY = 'active_key'
export const SAVED_DESCRIPTION = 'saved_description'
export const INVITED_BOARDS_USERS = 'invited_boards_users'
export const SEARCH_SUGGESTIONS = 'search_suggestions'
export const IS_META_STATUS = 'is_meta_status'
export const IS_GOOGLE_STATUS = 'is_google_status'
