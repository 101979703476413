import React from 'react'
import Text from '@components/Text/text'
import BaordsDetailsNav from '@components/Boards/BoardList/BaordsDetailsNav'
import BoardsDetailsSection from '@components/Boards/BoardList/BoardsDetailsSection'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import styles from './boardsDetail.module.css'

const BoardsDetail = () => {
  const history = useHistory()
  const boardDetailData = useSelector(state => state?.discoverAds?.boardDetailData)

  return (
    <>
      <div style={{ marginTop: 40, marginLeft: 52, marginRight: 52 }}>
        <div style={{ display: 'flex', marginBottom: 20 }}>
          <Text
            size={14}
            onClick={() => history.push('/dashboard/boards')}
            cursor
            className={styles.learningTop}
          >
            Boards
          </Text>
          <div className={styles.slash}> /</div>
          <Text className={styles.catagory}>{boardDetailData?.board_name}</Text>
        </div>
        <BaordsDetailsNav data={boardDetailData} />
        <BoardsDetailsSection data={boardDetailData?.ads} />
      </div>
    </>
  )
}
export default BoardsDetail
