import React, { useEffect, useState } from 'react'
import { Card, message, Spin } from 'antd'
import Text from '@components/Text/text'
import { SAVED_DESCRIPTION } from '@utils/constants'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import { useDispatch, useSelector } from 'react-redux'
import { RequestService } from '@services/requests'
import copyIcon from '@images/DiscoverAds/Modal/copy-icon.png'
import styles from './modalCard.module.css'

const ModalCard = ({ adsData }) => {
  const dispatch = useDispatch()
  const [saveData, setSaveData] = useState('')
  const [loader, setLoader] = useState(false)
  const fetchDescription = async () => {
    setLoader(true)
    dispatch({ type: SAVED_DESCRIPTION, payload: [] })
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      image_url: adsData?.image_url,
    }
    try {
      if (!adsData.description && !adsData.title) {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        setSaveData(response?.data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }
  useEffect(() => {
    fetchDescription()
    // eslint-disable-next-line
  }, [adsData])
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const [isCopied, setIsCopied] = useState(false)
  const handleCopyToClipboard = () => {
    const textToCopy = adsData?.additional_info?.ad_link
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true)
        message.success('URL copied to clipboard!')
        setTimeout(() => setIsCopied(false), 2000)
      })
      .catch(() => {
        message.error('Failed to copy text')
      })
  }

  return (
    <Card>
      {
        // eslint-disable-next-line
        adsData?.origin?.provider === 'meta' ? (
          <>
            {
              // eslint-disable-next-line
              adsData?.image_url ? (
                <img
                  src={adsData.image_url}
                  style={{ width: '-webkit-fill-available' }}
                  alt="thumbnail"
                />
              ) : adsData?.video_url ? (
                <video style={{ width: '100%', height: '300px' }} controls src={adsData.video_url}>
                  <track
                    kind="captions"
                    srcLang="en"
                    src="captions_en.vtt"
                    label="English captions"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={noPreviewImage}
                  style={{ width: '-webkit-fill-available' }}
                  alt="noPreviewImage"
                />
              )
            }
          </>
        ) : // eslint-disable-next-line
        adsData?.origin?.provider === 'google' ? (
          <>
            {
              // eslint-disable-next-line
              adsData?.image_url && !adsData?.video_url ? (
                <img
                  src={adsData.image_url}
                  style={{ width: '-webkit-fill-available' }}
                  alt="thumbnail"
                />
              ) : adsData?.video_url ? (
                <iframe
                  style={{ width: '-webkit-fill-available', height: '300px' }}
                  title="video"
                  src={`https://www.youtube.com/embed/${adsData?.video_url}?mute=0&autoplay=1`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ) : (
                <img
                  src={noPreviewImage}
                  style={{ width: '-webkit-fill-available' }}
                  alt="noPreviewImage"
                />
              )
            }
          </>
        ) : (
          ''
        )
      }

      <Text className={styles.description}>
        {
          // eslint-disable-next-line
          adsData?.description ? (
            adsData?.description
          ) : loader ? (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          ) : (
            saveData?.description
          )
        }
      </Text>
      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={styles.learnButton}
          onClick={() => {
            window.open(adsData?.ad_snapshot_url, '_blank')
          }}
        >
          Learn more
        </button>
        {adsData?.additional_info?.ad_link ? (
          <button className={styles.copyCTA} type="button" onClick={handleCopyToClipboard}>
            {isCopied ? 'URL Copied!' : 'Copy CTA URL'}
            <img src={copyIcon} style={{ marginLeft: 6, marginTop: -6 }} alt="copy-icon" />
          </button>
        ) : (
          ''
        )}
      </div>
    </Card>
  )
}

export default ModalCard
