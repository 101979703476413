import React, { useState } from 'react'
import { Button, Col, message, Row, Tooltip } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Text from '@components/Text/text'
import copyIcon from '@images/DiscoverAds/Modal/DetailsCard/Copy to CSV - Orange.png'
import downloadIcon from '@images/DiscoverAds/Modal/DetailsCard/download.png'
import viewIcon from '@images/DiscoverAds/Modal/DetailsCard/view.png'
import thumbnailIcon from '@images/DiscoverAds/Modal/DetailsCard/thumbnail.png'
import styles from './action.module.css'

const Actions = ({ adsData }) => {
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const savedDescription = useSelector(state => state?.discoverAds?.savedDescription)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyToClipboard = () => {
    const textToCopy = !adsData?.description ? savedDescription.description : adsData?.description
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true)
        message.success('Text copied to clipboard!')
        setTimeout(() => setIsCopied(false), 2000)
      })
      .catch(() => {
        message.error('Failed to copy text')
      })
  }

  const handleDownloadMediaForMeta = async () => {
    try {
      if (adsData?.image_url) {
        // Handle image download
        const imageUrl = adsData.image_url
        const response = await fetch(imageUrl, { mode: 'cors' }) // Enable CORS handling
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'image.png') // Specify image file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        message.success('Image downloaded successfully')
      } else if (adsData?.video_url) {
        // Handle video download
        const videoUrl = adsData.video_url
        const response = await fetch(videoUrl, { mode: 'cors' }) // Fetch the video file as blob
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'video.mp4') // Force download as video.mp4
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        message.success('Video downloaded successfully')
      } else {
        message.info('No media URL available to download')
      }
    } catch (error) {
      message.error(`Failed to download media: ${error.message}`)
    }
  }

  const handleDownloadFilesForMeta = async () => {
    // 1. Download the text file (description)
    const adText = adsData?.description || 'No description available'
    const textBlob = new Blob([adText], { type: 'text/plain' })
    const textUrl = URL.createObjectURL(textBlob)
    const textLink = document.createElement('a')
    textLink.href = textUrl
    textLink.download = 'ad-text.txt' // File name for the text file
    document.body.appendChild(textLink)
    textLink.click()
    document.body.removeChild(textLink)

    // 2. Check if the image_url is present, if not, check for the video_url
    if (adsData?.image_url) {
      if (adsData?.image_url) {
        const imageUrl = adsData?.image_url
        try {
          // Fetch the image data
          const response = await fetch(imageUrl, { mode: 'cors' })
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

          // Convert the response to a blob
          const blob = await response.blob()

          // Create an anchor element
          const element = document.createElement('a')
          // Create a URL for the blob and set it as the href of the anchor
          element.href = URL.createObjectURL(blob)
          // Set the download attribute to specify the file name
          element.download = 'image.png'

          // Append the anchor to the document and trigger a click to start the download
          document.body.appendChild(element)
          element.click()

          // Clean up: remove the anchor from the document and revoke the blob URL
          document.body.removeChild(element)
          URL.revokeObjectURL(element.href)
        } catch (error) {
          console.error('Image download failed:', error)
        }
      }
    } else if (adsData?.video_url) {
      const videoUrl = adsData.video_url
      try {
        // Create a download link for the video
        const link = document.createElement('a')
        link.href = videoUrl
        link.setAttribute('download', 'video.mp4') // Force download as video.mp4

        // Trigger the download
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('Failed to download video:', error)
      }
    } else {
      console.info('No media URL available to download')
    }
  }
  const handleDownloadFilesForGoogle = async () => {
    // 1. Download the text file (description)
    const adText = !adsData?.description ? savedDescription.description : adsData?.description
    const textBlob = new Blob([adText], { type: 'text/plain' })
    const textUrl = URL.createObjectURL(textBlob)
    const textLink = document.createElement('a')
    textLink.href = textUrl
    textLink.download = 'ad-text.txt' // File name for the text file
    document.body.appendChild(textLink)
    textLink.click()
    document.body.removeChild(textLink)

    // 2. Check if the image_url is present, if not, check for the video_url
    if (adsData?.image_url) {
      const imageUrl = adsData.image_url
      try {
        // Fetch the image data
        const response = await fetch(imageUrl)
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

        const blob = await response.blob()

        // Create a temporary link element
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'image.png') // Force download as image.png

        // Trigger the download
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('Failed to download image:', error)
      }
    } else if (adsData?.video_url) {
      const videoUrl = adsData.video_url
      try {
        // Create a download link for the video
        const link = document.createElement('a')
        link.href = videoUrl
        link.setAttribute('download', 'video.mp4') // Force download as video.mp4

        // Trigger the download
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('Failed to download video:', error)
      }
    } else {
      console.info('No media URL available to download')
    }
  }
  const handleDownloadMediaForGoogle = async () => {
    try {
      if (adsData?.image_url && !adsData?.video_url) {
        // Handle image download
        const imageUrl = adsData.image_url
        const response = await fetch(imageUrl, { mode: 'cors' }) // Enable CORS handling
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'image.png') // Specify image file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        message.success('Image downloaded successfully')
      } else if (adsData?.video_url) {
        const youtubeUrl = `https://www.youtube.com/watch?v=${adsData?.video_url}`
        const proxyUrl = 'https://corsproxy.io/?'

        axios
          .get(proxyUrl + youtubeUrl, {
            responseType: 'arraybuffer',
            withCredentials: false,
          })
          .then(response => {
            const blob = new Blob([response.data], { type: 'video/mp4' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'video.mp4'
            a.click()
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        message.info('No media URL available to download')
      }
    } catch (error) {
      message.error(`Failed to download media: ${error.message}`)
    }
  }

  return (
    <div>
      <Text style={{ fontSize: 14, color: '#67696B', marginTop: 24 }}>Actions</Text>
      <div style={{ marginTop: 12 }}>
        <Row gutter={16} justify="space-between" align="middle">
          <Col xs={24} sm={12} md={6}>
            <div>
              <Button
                className={styles.hoverButton}
                style={{
                  width: '100%',
                  border: '1px solid #DFE3E4',
                  textTransform: 'capitalize',
                  color: '#000000',
                }}
                onClick={handleCopyToClipboard}
                disabled={
                  (!savedDescription?.description && !adsData?.description) ||
                  savedDescription?.description === null
                }
              >
                <img style={{ marginTop: -8, fontSize: 14 }} src={copyIcon} alt="icon" />
                <span style={{ marginLeft: 8, fontWeight: 'bold', fontSize: 12 }}>
                  {isCopied ? 'Copied!' : 'Copy Script'}
                </span>
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={12} md={6}>
            {
              // eslint-disable-next-line
              adsData?.origin?.provider === 'meta' ? (
                <Tooltip title="Feature under development">
                  <div
                    disabled
                    style={{
                      background: '#DFE3E4',
                      border: '1px solid #DFE3E4',
                      textTransform: 'capitalize',
                      color: '#000000',
                      cursor: 'not-allowed',
                      height: '48px',
                      padding: '12px',
                      textAlign: 'center',
                    }}
                  >
                    <img style={{ marginTop: -8, fontSize: 14 }} src={downloadIcon} alt="icon" />
                    <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>
                      Download
                    </span>
                  </div>
                </Tooltip>
              ) : adsData?.origin?.provider !== 'meta' && adsData.video_url ? (
                <Tooltip title="Feature under development">
                  <div
                    disabled
                    style={{
                      background: '#DFE3E4',
                      border: '1px solid #DFE3E4',
                      textTransform: 'capitalize',
                      color: '#000000',
                      cursor: 'not-allowed',
                      height: '48px',
                      padding: '12px',
                      textAlign: 'center',
                    }}
                  >
                    <img style={{ marginTop: -8, fontSize: 14 }} src={downloadIcon} alt="icon" />
                    <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>
                      Download
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  className={styles.hoverButton}
                  style={{
                    width: '100%',
                    border: '1px solid #DFE3E4',
                    textTransform: 'capitalize',
                    color: '#000000',
                  }}
                  onClick={
                    adsData?.origin?.provider === 'meta'
                      ? handleDownloadFilesForMeta
                      : handleDownloadFilesForGoogle
                  }
                >
                  <img style={{ marginTop: -8, fontSize: 14 }} src={downloadIcon} alt="icon" />
                  <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Download</span>
                </Button>
              )
            }
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Button
              className={styles.hoverButton}
              style={{
                width: '100%',
                border: '1px solid #DFE3E4',
                textTransform: 'capitalize',
                color: '#000000',
              }}
              onClick={() => {
                window.open(adsData?.ad_snapshot_url, '_blank')
              }}
            >
              <img style={{ marginTop: -8, fontSize: 14 }} src={viewIcon} alt="icon" />
              <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Ad Link</span>
            </Button>
          </Col>
          <Col xs={24} sm={12} md={6}>
            {
              // eslint-disable-next-line
              adsData?.origin?.provider === 'meta' ? (
                <Tooltip title="Feature under development" style={{ textAlign: 'center' }}>
                  <div
                    disabled
                    style={{
                      background: '#DFE3E4',
                      border: '1px solid #DFE3E4',
                      textTransform: 'capitalize',
                      color: '#000000',
                      cursor: 'not-allowed',
                      height: '48px',
                      padding: '12px',
                      textAlign: 'center',
                    }}
                  >
                    <img style={{ marginTop: -8, fontSize: 14 }} src={thumbnailIcon} alt="icon" />
                    <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>
                      Thumbnail
                    </span>
                  </div>
                </Tooltip>
              ) : adsData?.origin?.provider !== 'meta' && adsData.video_url ? (
                <Tooltip title="Feature under development" style={{ textAlign: 'center' }}>
                  <div
                    disabled
                    style={{
                      background: '#DFE3E4',
                      border: '1px solid #DFE3E4',
                      textTransform: 'capitalize',
                      color: '#000000',
                      cursor: 'not-allowed',
                      height: '48px',
                      padding: '12px',
                      textAlign: 'center',
                    }}
                  >
                    <img style={{ marginTop: -8, fontSize: 14 }} src={thumbnailIcon} alt="icon" />
                    <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>
                      Thumbnail
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  className={styles.hoverButton}
                  style={{
                    width: '100%',
                    border: '1px solid #DFE3E4',
                    textTransform: 'capitalize',
                    color: '#000000',
                  }}
                  onClick={
                    adsData?.origin?.provider === 'meta'
                      ? handleDownloadMediaForMeta
                      : handleDownloadMediaForGoogle
                  } // Use the new function
                >
                  <img style={{ marginTop: -8, fontSize: 14 }} src={thumbnailIcon} alt="icon" />
                  <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Thumbnail</span>
                </Button>
              )
            }
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Actions
