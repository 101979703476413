import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { logoutUser } from '../../../store/actions'

const DropdownMenu = ({ children, setCustomers }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const userData = JSON.parse(localStorage.getItem('np_ads_grader:session'))
  const userEmail = userData?.user.email
  let items = []
  if (
    userEmail?.includes('npaccel.com') ||
    userEmail?.includes('npdigital.com') ||
    userEmail?.includes('neilpatel.com') ||
    userEmail?.includes('ubersuggest.com') ||
    userEmail?.includes('grayphite.com')
  ) {
    items = [
      {
        label: `Admin`,
        key: 'admin',
      },
      {
        label: `${t('settingsText')}`,
        key: 'settings',
      },
      {
        label: `${t('dashboardText')}`,
        key: 'dashboard',
      },
      {
        label: `${t('logoutText')}`,
        key: 'logOut',
      },
    ]
  } else {
    items = [
      {
        label: `${t('settingsText')}`,
        key: 'settings',
      },
      {
        label: `${t('dashboardText')}`,
        key: 'dashboard',
      },
      {
        label: `${t('logoutText')}`,
        key: 'logOut',
      },
    ]
  }

  const handleMenuClick = async ({ key }) => {
    if (key === 'logOut') {
      dispatch(logoutUser())
      setCustomers([])
      history.push('/')
      localStorage.removeItem('customer')
      localStorage.removeItem('report-generating')
      localStorage.removeItem('selectAccount')
      localStorage.removeItem('loginThroughWebLink')
      localStorage.removeItem('inviteFrom')
      localStorage.removeItem('showPopover')
      localStorage.removeItem('copied_report_unique_id')
      localStorage.removeItem('url')
      localStorage.removeItem('leadForm')
      localStorage.removeItem('showForm')
      localStorage.removeItem('term')
      localStorage.removeItem('content')
      localStorage.removeItem('source')
      localStorage.removeItem('?medium')
      localStorage.removeItem('campaign')
      localStorage.removeItem('_gl')
      localStorage.removeItem('ads_grader_saved_utms')
      localStorage.removeItem('isSearchFeature')
      localStorage.removeItem('profilePic')
      localStorage.removeItem('share_Baord_Link')
      localStorage.removeItem('public_status')
    } else if (key === 'dashboard') {
      history.push('/')
    } else if (key === 'admin') {
      // es-lint-disabled-next-line
      if (window.location.host === 'adsgrader.com') {
        window.open('https://np-ads-grader-api--prod.herokuapp.com/admin', '_blank')
      } else if (window.location.host === 'adsgrader-stage.netlify.app') {
        window.open('https://np-ads-grader-api.herokuapp.com/admin', '_blank')
      }
    } else {
      history.push(`/${key}`)
    }
  }

  return (
    <Dropdown
      style={{ cursor: 'pointer' }}
      menu={{ items, onClick: handleMenuClick }}
      trigger={['click']}
    >
      <Space>
        {children}
        <DownOutlined />
      </Space>
    </Dropdown>
  )
}

export default DropdownMenu
