import {
  ADS_LOCATION,
  ADD_BOARD,
  SET_BOARDS,
  SAVE_AD_TO_BOARD,
  RENAME_BOARD,
  DELETE_BOARD,
  BOARDS_SEARCH_KEYWORD,
  ADS_CHANNEL,
  ADS_FORMAT,
  ADS_SEARCH_KEYWORD,
  SPINNER,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  ADS_DATA,
  ADS_LOADER,
  CLEAR_STATE,
  TEMP_COUNT,
  ADS_PAGINATION,
  BOARD_DETAIL_DATA,
  ADS_DATA_FOR_NO_DATA_FOUND,
  BOARD_SEARCH,
  BOARDS_LIST,
  ADS_HOME_STATE,
  ADS_NO_DATA,
  EMPTY_ADS_PREVIEW_DATA,
  TAGS,
  FILTERED_TAGS,
  CLEAR_BRANDS_FLITER,
  BOARD_LIST,
  BOARDS_TAG_FILTER,
  MORE_DATA_LOADER,
  BOARDS_LIST_COUNT,
  SAVED_PAGE_ALL_DATA,
  SAVED_PAGE_FILTER_ALL_DATA,
  SAVED_PAGE_UNIQUE_TAGS,
  API_DATA_BOARDS_LIST,
  SCORE_AND_INDUSTRY,
  SCORE_AND_INDUSTRY_LOADER,
  RELATED_QUERIES_LOADER,
  RELATED_QUERIES,
  SHARED_BOARDS,
  SHARED_AND_PRIVATE_BOARDS_COUNT,
  ACTIVE_KEY,
  SAVED_DESCRIPTION,
  INVITED_BOARDS_USERS,
  SEARCH_SUGGESTIONS,
  IS_META_STATUS,
  IS_GOOGLE_STATUS,
} from '@utils/constants'

const initialState = {
  isGoogleStatus: false,
  isMetaStatus: false,
  searchSuggestions: [],
  invitedBoardsUsers: [],
  savedDescription: [],
  activeKey: '',
  sharedAndPrivateBoardsCount: 0,
  sharedBoardsList: [],
  relatedQueriesLoader: false,
  relatedQueries: [],
  scoreAndIndustry: [],
  scoreAndIndustryLoader: false,
  apiDataBoardsList: [],
  hasMore: false,
  loading: false,
  boards: [],
  savedAds: {},
  searchKeyword: '',
  channels: 'Google',
  formats: '',
  spinner: false,
  cleanedDataCount: '',
  adsPreviewData: [],
  adsPreviewDataForNoDataFound: [],
  tempCount: 0,
  adsPagination: '',
  boardDetailData: [],
  boardSearch: '',
  boardsList: [],
  homeState: true,
  adsNoData: false,
  tags: [],
  filteredTags: [],
  brandsFilter: false,
  boardList: [],
  boardsTagsFilter: [],
  moreDataLoader: false,
  boardListCount: 0,
  uniqueTags: [],
  allAdsData: [],
  filteredDiscoverData: [],
  location: '',
}

export const boardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOARD:
      return {
        ...state,
        boards: [...state.boards, action.payload],
      }
    case SET_BOARDS:
      return {
        ...state,
        boards: action.payload,
      }
    case RENAME_BOARD: {
      const { oldName, newName } = action.payload
      const updatedBoards = state.boards.map(board => (board === oldName ? newName : board))
      const updatedSavedAds = {
        ...state.savedAds,
        [newName]: state.savedAds[oldName],
      }
      delete updatedSavedAds[oldName]
      return {
        ...state,
        boards: updatedBoards,
        savedAds: updatedSavedAds,
      }
    }

    case DELETE_BOARD: {
      const updatedBoards = state.boards.filter(board => board !== action.payload)
      const { [action.payload]: _, ...updatedSavedAds } = state.savedAds
      return {
        ...state,
        boards: updatedBoards,
        savedAds: updatedSavedAds,
      }
    }
    case ADS_CHANNEL:
      return { ...state, channels: action.payload }
    case SAVE_AD_TO_BOARD:
      // eslint-disable-next-line
      const { board, ad } = action.payload
      // eslint-disable-next-line
      const isAdAlreadySaved = state.savedAds[board]?.some(savedAd => savedAd.id === ad.id)

      if (isAdAlreadySaved) {
        return state // Return the current state if the ad is already saved
      }

      return {
        ...state,
        savedAds: {
          ...state.savedAds,
          [board]: [...(state.savedAds[board] || []), ad],
        },
      }
    case ADS_FORMAT:
      return { ...state, formats: action.payload }
    case SPINNER:
      return { ...state, spinner: action.payload }
    case ADS_HOME_STATE:
      return { ...state, homeState: action.payload }
    case CLEANED_DATA_COUNT:
      return { ...state, cleanedDataCount: action.payload }
    case HAS_MORE:
      return { ...state, hasMore: action.payload }
    case ADS_DATA:
      return { ...state, adsPreviewData: [...state.adsPreviewData, ...action.payload] }
    case ADS_LOADER:
      return { ...state, loading: action.payload }
    case TEMP_COUNT: {
      return { ...state, tempCount: action.payload }
    }
    case EMPTY_ADS_PREVIEW_DATA:
      return { ...state, adsPreviewData: [] }
    case ADS_PAGINATION:
      return { ...state, adsPagination: action.payload }

    case BOARD_DETAIL_DATA:
      return { ...state, boardDetailData: action.payload }
    case BOARD_SEARCH:
      return { ...state, boardSearch: action.payload }
    case BOARDS_LIST:
      return { ...state, boardsList: action.payload }
    // return { ...state, boardsList: [...state.boardsList, ...action.payload] }
    case ADS_NO_DATA:
      return { ...state, adsNoData: action.payload }
    case TAGS:
      return { ...state, tags: action.payload }
    case FILTERED_TAGS:
      return { ...state, filteredTags: action.payload }
    case CLEAR_BRANDS_FLITER:
      return { ...state, brandsFilter: action.payload }
    case BOARD_LIST:
      return { ...state, boardList: action.payload }
    case BOARDS_TAG_FILTER:
      return { ...state, boardsTagsFilter: action.payload }
    case MORE_DATA_LOADER:
      return { ...state, moreDataLoader: action.payload }
    case BOARDS_LIST_COUNT:
      return { ...state, boardListCount: action.payload }
    case SAVED_PAGE_UNIQUE_TAGS:
      return { ...state, uniqueTags: action.payload }
    case SAVED_PAGE_FILTER_ALL_DATA:
      return { ...state, filteredDiscoverData: action.payload }
    case SAVED_PAGE_ALL_DATA:
      return { ...state, allAdsData: action.payload }
    case API_DATA_BOARDS_LIST:
      return { ...state, apiDataBoardsList: action.payload }
    case SCORE_AND_INDUSTRY:
      return { ...state, scoreAndIndustry: action.payload }
    case SCORE_AND_INDUSTRY_LOADER:
      return { ...state, scoreAndIndustryLoader: action.payload }
    case RELATED_QUERIES_LOADER:
      return { ...state, relatedQueriesLoader: action.payload }
    case RELATED_QUERIES:
      return { ...state, relatedQueries: action.payload }
    case SHARED_BOARDS:
      return { ...state, sharedBoardsList: action.payload }
    case SHARED_AND_PRIVATE_BOARDS_COUNT:
      return { ...state, sharedAndPrivateBoardsCount: action.payload }
    case ACTIVE_KEY:
      return { ...state, activeKey: action.payload }
    case ADS_SEARCH_KEYWORD:
      return { ...state, searchKeyword: action.payload }
    case ADS_LOCATION:
      return { ...state, location: action.payload }
    case SAVED_DESCRIPTION:
      return { ...state, savedDescription: action.payload }
    case INVITED_BOARDS_USERS:
      return { ...state, invitedBoardsUsers: action.payload }
    case SEARCH_SUGGESTIONS:
      return { ...state, searchSuggestions: action.payload }
    case IS_META_STATUS:
      return { ...state, isMetaStatus: action.payload }
    case IS_GOOGLE_STATUS:
      return { ...state, isGoogleStatus: action.payload }
    default:
      return state
  }
}
