import React, { useState } from 'react'
import { Text } from '@components'
import { Card, Dropdown, Menu, Button, Input, Spin, message, Modal, Tooltip } from 'antd'
import verticalDivider from '@images/AdsPreview/verticalDivider.png'
import { CheckOutlined, CloseOutlined, PushpinOutlined } from '@ant-design/icons'
import { RequestService } from '@services/requests'
import activeIcon from '@images/AdsPreview/activeIcon.png'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import GoogleIcon from '@images/DiscoverAds/SearchBar/Meta Icons.png'
import MetaIcon from '@images/DiscoverAds/SearchBar/MetaIcon.png'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import SavedIcon from '@images/DiscoverAds/AdsCard/adSavedIcon.png'
import CopyIcon from '@images/DiscoverAds/ButtonIcons/Copy Link.png'
import CopyIconOrange from '@images/DiscoverAds/ButtonIcons/CopyLinkOrange.svg'
import AddIcon from '@images/DiscoverAds/ButtonIcons/add.png'
import AddIconOrange from '@images/DiscoverAds/ButtonIcons/addIconOrange.svg'
import ShareIcon from '@images/DiscoverAds/ButtonIcons/share.svg'
import ShareIconOrange from '@images/DiscoverAds/ButtonIcons/shareOrange.svg'
import MoreOptionsIcon from '@images/DiscoverAds/ButtonIcons/More Option.png'
import FollowIcon from '@images/DiscoverAds/MoreOptions/followBrandIcon.png'
import CopyLinkIcon from '@images/DiscoverAds/MoreOptions/copyIcon.png'
import PinIcon from '@images/DiscoverAds/MoreOptions/pinIcon.png'
import PinToBoard from '@components/Boards/PinToBoard'
import { API_DATA_BOARDS_LIST } from '@utils/constants'
import { addBoard } from '../../../store/actions'
import AdsModal from '../AdsModal'
import styles from './adsCard.module.css'

const AdsCard = ({
  savedAdsFlow,
  adsData,
  onShowModal,
  onPrevious,
  onNext,
  selectedData,
  isFirst,
  isLast,
  boardsList,
  isSavedInSavedAds,
  isAdPinned,
  boardDetailRoute,
}) => {
  const boardDetailData = useSelector(state => state?.discoverAds?.boardDetailData)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [test, setTest] = useState(apiDataBoardsList)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const hasMore = useSelector(state => state?.discoverAds?.hasMore)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPinModalOpen, setIsPinModalOpen] = useState(false)
  const [isSaved, setIsSaved] = useState(adsData?.status)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const savedAds = useSelector(state => state?.discoverAds?.savedAds)
  const dispatch = useDispatch()
  const showModal = () => {
    setIsModalOpen(true)
    onShowModal()
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handlePinOk = () => {
    // Logic to pin the ad to a board
    setIsPinModalOpen(false)
  }

  const handlePinCancel = () => {
    setIsPinModalOpen(false)
  }

  const handleSearch = e => {
    setSearchTerm(e.target.value)
  }

  const handleSaveAds = async () => {
    setIsSaveLoading(true)
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      is_active: true,
    }
    try {
      await RequestService.post('/save/ads/', data)
      await RequestService.post('/ads-preview/competitors-prod/', data)
      setIsSaveLoading(false)
      setIsSaved(true)
    } catch (error) {
      setIsSaveLoading(false)
      setIsSaved(false)
      console.error('Error creating board:', error)
    }
  }
  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setTest(response?.data)
    } catch (error) {
      // console.log('🚀 ~ hanldeDiscoverAdsBoard ~ error:', error)
    }
  }
  const handlePinToBoard = async (value, id) => {
    const trimmedValue = value.trim().toLowerCase()
    const exists = test.some(board => board.name.toLowerCase() === trimmedValue)

    if (!exists) {
      const data = {
        name: value,
      }
      try {
        const response = await RequestService.post('/create/boards/', data)
        dispatch(addBoard(response))
        hanldeDiscoverAdsBoard()
      } catch (error) {
        console.error('Error creating board:', error)
      }
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        board: id,
        is_pinned: true,
      }
      try {
        await RequestService.post('/create/pinned-ads/', data)
        hanldeDiscoverAdsBoard()
        message.success('Pinned to board')
        setIsPinModalOpen(false)
      } catch (error) {
        console.error('Error pinning ad to board:', error)
      }
    }
  }
  const handlePinToBoardFromDetailPage = async (value, id) => {
    const trimmedValue = value.trim().toLowerCase()
    const exists = test.some(board => board.name.toLowerCase() === trimmedValue)

    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      board: id,
      is_pinned: true,
    }
    try {
      await RequestService.post('/create/pinned-ads/', data)
      hanldeDiscoverAdsBoard()
      message.success('Pinned to board')
      setIsPinModalOpen(false)
    } catch (error) {
      console.error('Error pinning ad to board:', error)
    }
  }

  const handleSelectOrCreate = async (value, id) => {
    const trimmedValue = value.trim().toLowerCase()
    const exists = test.some(board => board.name.toLowerCase() === trimmedValue)

    if (!exists) {
      const data = {
        name: value,
      }
      try {
        const response = await RequestService.post('/create/boards/', data)
        dispatch(addBoard(response))
        const datas = {
          // eslint-disable-next-line
          ad_id: adsData?._id,
          board: response.data.data.id,
        }
        try {
          await RequestService.post('/create/pinned-ads/', datas)
          hanldeDiscoverAdsBoard()
          message.success('Added to baord')
        } catch (error) {
          console.error('Error pinning ad to board:', error)
        }
        hanldeDiscoverAdsBoard()
      } catch (error) {
        console.error('Error creating board:', error)
      }
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        board: id,
      }
      try {
        await RequestService.post('/create/pinned-ads/', data)
        hanldeDiscoverAdsBoard()
        message.success('Added to baord')
      } catch (error) {
        console.error('Error pinning ad to board:', error)
      }
    }
  }

  const filteredItems = test.filter(board =>
    board?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const [isCopied, setIsCopied] = useState(false)

  const handleCopyToClipboard = () => {
    const textToCopy = adsData?.ad_snapshot_url
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true)
        message.success('Link copied!')
        setTimeout(() => setIsCopied(false), 2000)
      })
      .catch(() => {
        message.error('Failed to copy Link')
      })
  }

  const saveOptionMenu = (
    <Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
      <Menu.Item key="search" disabled>
        <Input
          placeholder="Search or create board"
          value={searchTerm}
          onChange={handleSearch}
          // eslint-disable-next-line
          onPressEnter={() => (handleSelectOrCreate(searchTerm), setSearchTerm(''))}
        />
      </Menu.Item>

      {filteredItems.map((board, index) => {
        // Check if the current ad is pinned in the board's pinned_ads array
        // eslint-disable-next-line
        const isPinned = board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)

        return (
          <React.Fragment key={board?.id}>
            {isPinned ? (
              <Menu.Item key={board?.id} disabled>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <span style={{ color: '#FF5E29' }}># </span>
                    {board.name}
                  </div>
                  <CheckOutlined style={{ color: '#f16434' }} />
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item key={board?.id} onClick={() => handleSelectOrCreate(board.name, board.id)}>
                <span style={{ color: '#FF5E29' }}># </span>
                {board.name}
              </Menu.Item>
            )}
          </React.Fragment>
        )
      })}
    </Menu>
  )

  const pinnedOptionsMenu = (
    <Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
      {filteredItems.map((board, index) => {
        // eslint-disable-next-line
        const isPinned = board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)

        return (
          <React.Fragment key={board?.id}>
            {isPinned ? (
              <Menu.Item key={board?.id} disabled>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <div>
                    <span style={{ color: '#FF5E29' }}># </span>
                    {board.name}
                  </div>
                  <CheckOutlined style={{ color: '#f16434' }} />
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item key={board?.id} onClick={() => handlePinToBoard(board?.name, board?.id)}>
                <span style={{ color: '#FF5E29' }}># </span>
                {board.name}
              </Menu.Item>
            )}
          </React.Fragment>
        )
      })}
    </Menu>
  )

  const moreOptionsMenu = (
    <Menu>
      <Menu.Item key="1" disabled>
        <div style={{ display: 'flex', gap: 8, opacity: 1 }}>
          <img src={FollowIcon} alt="follow-icon" />
          Follow Brand
        </div>
        <div style={{ opacity: 0.7 }}>
          <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
            Follow specific brands to track their ads
          </Text>
        </div>
      </Menu.Item>

      <Menu.Item key="2" onClick={handleCopyToClipboard}>
        <div style={{ display: 'flex', gap: 8 }}>
          <img src={CopyLinkIcon} alt="copy-icon" />
          {isCopied ? 'Link Copied' : 'Copy Link'}
        </div>
        <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
          {isCopied ? '' : ' Copy the link to share this'}
        </Text>
      </Menu.Item>
      {isAdPinned && boardDetailRoute === 'true' ? (
        <Menu.Item key="3">
          <div style={{ display: 'flex', gap: 8 }}>
            {/* <img src={PinIcon} alt="pin-icon" /> */}
            <PushpinOutlined style={{ width: 24, height: 24, fontSize: 24 }} />
            Pinned To Board
          </div>
          <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
            This ad is already pinned to Board.
          </Text>
        </Menu.Item>
      ) : (
        <>
          {boardDetailRoute === 'true' ? (
            // asdasadasd adsData?._id
            <Menu.Item
              key="3"
              onClick={() =>
                handlePinToBoardFromDetailPage(boardDetailData?.board_name, boardDetailData?.id)
              }
            >
              <div style={{ display: 'flex', gap: 8 }}>
                <img src={PinIcon} alt="pin-icon" />
                Pin to Board
              </div>
              <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                Pin this ad to a board to organize ads.
              </Text>
            </Menu.Item>
          ) : (
            <Menu.Item key="3" onClick={() => setIsPinModalOpen(true)}>
              <div style={{ display: 'flex', gap: 8 }}>
                <img src={PinIcon} alt="pin-icon" />
                Pin to Board
              </div>
              <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                Pin this ad to a board to organize ads.
              </Text>
            </Menu.Item>
          )}
        </>
      )}
    </Menu>
  )

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.profileContainer}>
          {/* eslint-disable-next-line */}
          <div className={styles.profile} onClick={() => showModal(adsData._id)}>
            {adsData?.additional_info?.page_profile_picture_url ? (
              <div>
                <img
                  src={adsData?.additional_info?.page_profile_picture_url}
                  alt="profile"
                  width={48}
                  height={48}
                  style={{ borderRadius: '48px' }}
                />
              </div>
            ) : (
              ''
            )}

            <div>
              {/* <div style={{ display: 'flex' }}> */}
              <Text isBold size={16}>
                {adsData?.additional_info?.page_name}
                {/* eslint-disable-next-line */}
                {/* {adsData?._id} */}
              </Text>

              {/* </div> */}

              <div style={{ display: 'flex', gap: 8 }}>
                {
                  // eslint-disable-next-line
                  adsData?.origin?.provider === 'meta' ? (
                    <div style={{ marginTop: -5 }}>
                      <img src={MetaIcon} alt="meta-icon" width="100%" height={16} />
                    </div>
                  ) : adsData?.origin?.provider === 'google' ? (
                    <div style={{ marginTop: -5 }}>
                      <img src={GoogleIcon} alt="google-icon" width={18} height={16} />
                    </div>
                  ) : (
                    ''
                  )
                }{' '}
                <img src={verticalDivider} height={12} style={{ marginTop: 5 }} alt="divider" />
                {adsData?.additional_info?.ad_creation_time ? (
                  <Text size={14} style={{ color: '#8F8F8F' }}>
                    {dayjs(adsData?.additional_info?.ad_creation_time).format('MMM D, YYYY')}
                  </Text>
                ) : (
                  ''
                )}
                <img src={verticalDivider} height={12} style={{ marginTop: 5 }} alt="divider" />
                {
                  // eslint-disable-next-line
                  adsData?.additional_info?.is_active === 'false' ||
                  adsData?.additional_info?.is_active === false ? (
                    ''
                  ) : adsData?.additional_info?.is_active === 'true' ||
                    adsData?.additional_info?.is_active === true ? (
                    <img src={activeIcon} className={styles.profileIcons} alt="active-icon-pic" />
                  ) : (
                    ''
                  )
                }
                <Text size={14} style={{ color: '#8F8F8F' }}>
                  {
                    // eslint-disable-next-line
                    adsData?.additional_info?.is_active === 'true' ||
                    adsData?.additional_info?.is_active === true
                      ? 'Currently Active'
                      : adsData?.additional_info?.is_active === 'false' ||
                        adsData?.additional_info?.is_active === false
                      ? 'Ended'
                      : ''
                  }
                </Text>
                {isAdPinned ? (
                  <>
                    <img src={verticalDivider} height={12} style={{ marginTop: 5 }} alt="divider" />
                    <PushpinOutlined
                      color="#f16434"
                      style={{ color: '#f16434', fontSize: '15px' }}
                    />{' '}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {/* Dropdown */}
          <div id="ads-card-more-options">
            <Dropdown
              overlay={moreOptionsMenu}
              getPopupContainer={() => document.getElementById('ads-card-more-options')}
              trigger={['click']} // Ensures the dropdown is triggered on click
            >
              <button
                type="submit"
                style={{ border: 'none', padding: 0, background: 'none', display: 'inline-flex' }}
              >
                <img
                  src={MoreOptionsIcon}
                  width={20}
                  height={20}
                  alt="more-options"
                  style={{ cursor: 'pointer' }} // Ensures the cursor is a pointer
                />
              </button>
            </Dropdown>
          </div>
        </div>
        {
          // eslint-disable-next-line
          savedAdsFlow === 'true' ? (
            <>
              {adsData?.origin?.provider === 'meta' && savedAdsFlow === 'true' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <video
                          style={{ width: '100%', height: '300px' }}
                          src={adsData?.video_url}
                          onPlay={e => e.preventDefault()} // Prevents playing
                        >
                          <track
                            kind="captions"
                            srcLang="en"
                            src="captions_en.vtt"
                            label="English captions"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )}
              {adsData?.origin?.provider === 'google' && savedAdsFlow === 'true' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.image_url && adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={adsData.image_url ?? noPreviewImage}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )}
            </>
          ) : // eslint-disable-next-line
          selectedChannel === 'Meta' ? (
            // eslint-disable-next-line
            <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
              {
                // eslint-disable-next-line
                adsData?.image_url ? (
                  <img
                    src={adsData.image_url ?? noPreviewImage}
                    className={styles.cardImage}
                    alt="card-img"
                  />
                ) : adsData?.video_url ? (
                  <div style={{ position: 'relative' }}>
                    <video
                      style={{ width: '100%', height: '300px' }}
                      src={adsData?.video_url}
                      onPlay={e => e.preventDefault()} // Prevents playing
                    >
                      <track
                        kind="captions"
                        srcLang="en"
                        src="captions_en.vtt"
                        label="English captions"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <svg
                      height="20%"
                      version="1.1"
                      viewBox="0 0 68 48"
                      width="20%"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fill: 'white',
                        opacity: '0.8',
                        cursor: 'pointer',
                      }}
                    >
                      <path
                        className="ytp-large-play-button-bg"
                        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                        fill="#f00"
                      />
                      <path d="M 45,24 27,14 27,34" fill="#fff" />
                    </svg>
                  </div>
                ) : (
                  <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                )
              }
            </div>
          ) : selectedChannel === 'Google' ? (
            // eslint-disable-next-line
            <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
              {
                // eslint-disable-next-line
                adsData?.image_url && !adsData?.video_url ? (
                  <img
                    src={adsData.image_url ?? noPreviewImage}
                    className={styles.cardImage}
                    alt="card-img"
                  />
                ) : adsData?.image_url && adsData?.video_url ? (
                  <div style={{ position: 'relative' }}>
                    <img
                      src={adsData.image_url ?? noPreviewImage}
                      className={styles.cardImage}
                      alt="card-img"
                    />
                    <svg
                      height="20%"
                      version="1.1"
                      viewBox="0 0 68 48"
                      width="20%"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fill: 'white',
                        opacity: '0.8',
                        cursor: 'pointer',
                      }}
                    >
                      <path
                        className="ytp-large-play-button-bg"
                        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                        fill="#f00"
                      />
                      <path d="M 45,24 27,14 27,34" fill="#fff" />
                    </svg>
                  </div>
                ) : (
                  <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                )
              }
            </div>
          ) : (
            ''
          )
        }

        <div className={styles.buttonContainer} id="area">
          {isSaveLoading && !isSavedInSavedAds && !isSaved ? (
            <div style={{ marginLeft: '65px' }}>
              <Spin />
            </div>
          ) : (
            <button
              type="button"
              className={`${styles.saveButton} ${isSaved || isSavedInSavedAds ? styles.saved : ''}`} // Change style based on save status
              onClick={handleSaveAds}
            >
              {isSaved || isSavedInSavedAds ? (
                <div>
                  <img style={{ marginTop: -7 }} src={SavedIcon} alt="save-icon" />
                  <span style={{ paddingLeft: 6 }}>Ad Saved</span>
                </div>
              ) : (
                <Text className={styles.savedText} size={14}>
                  Save
                </Text>
              )}
            </button>
          )}

          <div className={styles.iconButtonContainer} id="adsCardTooltip">
            <Tooltip
              title="Copy ad link"
              color="rgb(32, 32, 32)"
              getPopupContainer={() => document.getElementById('adsCardTooltip')}
              placement="top"
            >
              <button
                className={styles.addButton}
                type="button"
                onClick={handleCopyToClipboard}
                id="first"
              >
                <img src={CopyIcon} alt="copy-icon" className={styles.copyIcon} />
                <img
                  src={CopyIconOrange}
                  alt="copy-icon-orange"
                  className={styles.copyIconOrange}
                />
              </button>
            </Tooltip>
            <Tooltip
              title="View ad"
              color="rgb(32, 32, 32)"
              getPopupContainer={() => document.getElementById('adsCardTooltip')}
              placement="top"
            >
              <button
                className={styles.addButton}
                type="button"
                onClick={() => {
                  window.open(adsData?.ad_snapshot_url, '_blank')
                }}
              >
                <img src={ShareIcon} alt="share-icon" width={20} className={styles.copyIcon} />
                <img
                  src={ShareIconOrange}
                  alt="share-icon"
                  width={20}
                  className={styles.copyIconOrange}
                />
              </button>
            </Tooltip>
            <Dropdown
              onClick={hanldeDiscoverAdsBoard}
              overlay={saveOptionMenu}
              getPopupContainer={() => document.getElementById('area')}
              trigger={['click']}
              overlayStyle={{ width: 'max-content' }}
            >
              <Tooltip
                title="Add to board"
                color="rgb(32, 32, 32)"
                getPopupContainer={() => document.getElementById('adsCardTooltip')}
                placement="top"
              >
                <button className={styles.addButton} type="button">
                  <img src={AddIcon} alt="add-icon" className={styles.addIcon} />
                  <img
                    src={AddIconOrange}
                    alt="hovered-add-icon"
                    className={`${styles.addIcon} ${styles.hoveredIcon}`}
                  />
                </button>
              </Tooltip>
            </Dropdown>
          </div>
        </div>
      </Card>

      {isModalOpen && selectedData && (
        <AdsModal
          adsData={selectedData}
          modalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          onPrevious={onPrevious}
          onNext={onNext}
          isFirst={isFirst}
          isLast={isLast}
          boardsList={test}
        />
      )}
      {/* <PinToBoard
        adsData={adsData}
        isPinModalOpen={isPinModalOpen}
        handleOk={handlePinOk}
        handleCancel={handlePinCancel}
        boardsList={test}
      /> */}
      <Modal
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Pin ad to Board</span>
            <CloseOutlined onClick={() => setIsPinModalOpen(false)} style={{ cursor: 'pointer' }} />
          </div>
        }
        visible={isPinModalOpen}
        onCancel={() => setIsPinModalOpen(false)}
        footer={null}
      >
        <Dropdown
          overlay={pinnedOptionsMenu}
          // getPopupContainer={() => document.getElementById('area')}
          trigger={['click']}
          overlayStyle={{ width: 'max-content' }}
        >
          <Input
            placeholder="Search or create board"
            value={searchTerm}
            onChange={handleSearch}
            onClick={() => setIsDropdownVisible(prev => !prev)}
            onPressEnter={() => {
              handleSelectOrCreate(searchTerm)
              setSearchTerm('')
              setIsDropdownVisible(false)
            }}
          />
        </Dropdown>
      </Modal>
    </>
  )
}

export default AdsCard
