import React from 'react'
import Text from '@components/Text/text'

import { Col, Progress, Row, Spin, Tag, Modal, Collapse, List } from 'antd'
import { useSelector } from 'react-redux'
import { DownOutlined } from '@ant-design/icons'
import styles from './adsGraderAI.module.css'

const MagicAI = ({ adsData }) => {
  const { Panel } = Collapse
  const scoresAndIndustry = useSelector(state => state?.discoverAds?.scoreAndIndustry)
  const scoreAndIndustryLoader = useSelector(state => state?.discoverAds?.scoreAndIndustryLoader)
  // eslint-disable-next-line
  const calculateFill = score => {
    if (score <= 25) {
      return '#FE8485'
    } // eslint-disable-next-line
    else if (score > 25 && score <= 50) {
      return '#F8BC54'
    } else if (score > 50 && score <= 75) {
      return '#2B4EFF'
    } else if (score > 75) {
      return 'green'
    }
  }

  return (
    <div style={{ marginTop: 24, border: '1px solid #DFE3E4' }}>
      <Text isBold size={18} style={{ padding: 24 }}>
        Ads Grader AI
      </Text>
      <div style={{ borderBottom: '1px solid #DFE3E4' }} />

      <Row>
        <Col span={16} style={{ padding: 24 }}>
          {scoreAndIndustryLoader ? (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          ) : (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text isBold>Ad Score - {scoresAndIndustry?.score}%</Text>
              </div>
              <div id="progressBar">
                <Progress
                  percent={scoresAndIndustry?.score}
                  showInfo={false}
                  strokeColor={calculateFill(scoresAndIndustry?.score)}
                  strokeWidth="20px"
                  strokeLinecap="butt"
                />
              </div>{' '}
              <div id="ModalCollapseSetting" style={{ marginTop: '24px', background: 'white' }}>
                <Collapse
                  expandIcon={({ isActive }) => (
                    <div className={styles.headingIcon}>
                      <DownOutlined rotate={isActive ? 180 : 360} />{' '}
                    </div>
                  )}
                  expandIconPosition="right"
                  defaultActiveKey={['1']}
                  ghost
                >
                  <Panel
                    header={
                      <Text size={18} isBold style={{ display: 'flex', alignItems: 'center' }}>
                        How can I improve
                      </Text>
                    }
                    key="1"
                  >
                    <div className={styles.panelContent}>
                      {scoresAndIndustry?.reasoning &&
                        // eslint-disable-next-line
                        (typeof scoresAndIndustry?.reasoning === 'string' ? (
                          <Text className={styles.reasonText}>{scoresAndIndustry?.reasoning}</Text>
                        ) : Array.isArray(scoresAndIndustry?.reasoning) ? (
                          scoresAndIndustry?.reasoning.map((reason, index) => (
                            // eslint-disable-next-line
                            <Text key={index} className={styles.reasonText}>
                              {`${index + 1}. ${
                                typeof reason === 'object' ? JSON.stringify(reason) : reason
                              }`}
                            </Text>
                          ))
                        ) : (
                          Object.entries(scoresAndIndustry?.reasoning).map(
                            ([key, value], index) => (
                              // eslint-disable-next-line
                              <Text key={index} className={styles.reasonText}>
                                {`${key}: ${value}`}
                              </Text>
                            )
                          )
                        ))}
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
          )}
        </Col>

        <Col span={8} style={{ borderLeft: '1px solid #DFE3E4' }}>
          <div style={{ padding: 24 }}>
            <Text isBold>Run Time</Text>
            <div>{adsData?.additional_info?.ad_time}</div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MagicAI
