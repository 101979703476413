/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Menu, message, Popover, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { generateReportForCustomer } from 'services/generateReport'
import { getParentCustomerId, sortByCreatedAt } from 'utils/helperFunctions'
import { fetchCustomersList } from '@services/fetchCustomersList'
import { fetchInvitersList } from '@services/fetchInvitersList'
import { notify } from '@utils/notify'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  updateGenerateReport,
  updateSelectedCustomer,
  updateSampleReport,
  singleReport,
  reportIndex,
  logoutUser,
} from '../../../store/actions'
import Select from '../index'
import styles from './customersSelect.module.scss'

const generateCustomersHTML = (customers, cb) =>
  customers.map(c => ({
    label: cb(c),
    key: c.id,
  }))

const CustomersSelect = ({
  settingPage,
  selectedUserId,
  setSelectedUserId,
  handleSelectUser,
  rightMenu,
  selectProps,
  setReports,
  disabled,
}) => {
  const [openGenerateReportPopover, setOpenGenerateReportPopover] = useState({})
  const [showRightMenu, setShowRightMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [customers, setCustomers] = useState([])
  const [zeroReportCustomers, setZeroReportCustomers] = useState([])
  const [inviters, setInviters] = useState([])
  const [zeroReportInviters, setZeroReportInviters] = useState([])
  const [isOpen, setIsOpen] = useState()
  const { t } = useTranslation()
  const history = useHistory()
  const [isInviters, setIsInviters] = useState(false)
  const [isCustomers, setIsCustomers] = useState(false)
  const inviteFromString = localStorage.getItem('inviteFrom')
  const inviteFrom = JSON?.parse(inviteFromString)
  const isBoardPrivate = inviteFrom?.isBoardPrivate
  const customerFromLocalStorage = localStorage.getItem('customer')
  const customerInviteFromLocalStorage = JSON.parse(localStorage.getItem('inviteFrom'))
  const isGenerateReport = useSelector(state => state?.generateReport)
  const customer = useSelector(state => state?.customer)
  const userDetail = useSelector(state => state?.user)
  const [generateReportLoading, setGenerateReportLoading] = useState({})
  const dispatch = useDispatch()
  const [openPopUp, setOpenPopUP] = useState(false)
  const updateGenerateReportPopover = id => {
    setIsOpen(pre => ({
      [id]: !pre?.[id],
    }))
    setOpenGenerateReportPopover(pre => ({
      [id]: !pre?.[id],
    }))
  }
  const updateGenerateLoading = id => {
    setGenerateReportLoading(pre => ({
      ...pre,
      [id]: !pre?.[id],
    }))
  }
  useEffect(() => {
    setIsLoading(true)
    fetchInvitersList()
      .then(res => {
        setIsInviters(true)

        if (res?.status === 200 && res.data?.inviter_customers?.length) {
          const sortedCustomers = res.data?.inviter_customers.sort(
            (a, b) => b.reports_count - a.reports_count
          )
          const tempZeroReportInviters = sortedCustomers.filter(
            item => item?.reports_count === 0 && !!item.descriptive_name
          )
          const tempNonZeroReportInviters = sortedCustomers.filter(
            item => item?.reports_count > 0 && !!item.descriptive_name
          )
          if (tempNonZeroReportInviters.length || tempZeroReportInviters.length) {
            setShowRightMenu(true)
          }
          setIsLoading(false)
          setIsInviters(true)
          if (!selectedUserId && setSelectedUserId) {
            setSelectedUserId(tempNonZeroReportInviters?.[0]?.id)
          }
          if (customerInviteFromLocalStorage?.email === userDetail?.user?.email) {
            const inviteCustomerData = tempNonZeroReportInviters?.filter(
              customer => customer.id === customerInviteFromLocalStorage.customer_id
            )
            if (isBoardPrivate) {
              dispatch(updateSelectedCustomer(sortedCustomers[0]))
            } else {
              dispatch(updateSelectedCustomer(inviteCustomerData[0]))
            }
          } else if (!customer?.id) {
            if (isBoardPrivate) {
              dispatch(updateSelectedCustomer(sortedCustomers[0]))
            }
            dispatch(updateSelectedCustomer(tempNonZeroReportInviters?.[0]))
          }
          setInviters(tempNonZeroReportInviters)
          setZeroReportInviters(tempZeroReportInviters)
        }
      })
      .catch(e => {
        setIsLoading(false)
        setIsInviters(true)
      })
    fetchCustomersList()
      .then(response => {
        console.log('response', response)
        if (response.data.length > 0) {
          // localStorage.setItem('isCustomers', true)
        }
        setIsLoading(false)
        setIsCustomers(true)
        if (
          response.status === 401 ||
          (response.status === 500 &&
            response.data.msg === 'Your permissions from Google have been revoked.')
        ) {
          dispatch(logoutUser())
          setCustomers([])
          history.push('/')
          notify(t('notification.tokenExpired'), 'error')
          localStorage.removeItem('customer')
          localStorage.removeItem('report-generating')
          localStorage.removeItem('selectAccount')
          localStorage.removeItem('loginThroughWebLink')
          localStorage.removeItem('inviteFrom')
          localStorage.removeItem('showPopover')
          localStorage.removeItem('copied_report_unique_id')
          localStorage.removeItem('url')
          localStorage.removeItem('leadForm')
          localStorage.removeItem('showForm')
          localStorage.removeItem('term')
          localStorage.removeItem('content')
          localStorage.removeItem('source')
          localStorage.removeItem('?medium')
          localStorage.removeItem('campaign')
          localStorage.removeItem('_gl')
          localStorage.removeItem('ads_grader_saved_utms')
          localStorage.removeItem('isSearchFeature')
          localStorage.removeItem('profilePic')
          localStorage.removeItem('share_Baord_Link')
          localStorage.removeItem('public_status')
        } else {
          // eslint-disable-next-line
          if (response.status === 200 && response.data?.length) {
            localStorage.setItem('customerslength', JSON.stringify(true))
            const sortedCustomers = response.data.sort((a, b) => b.reports_count - a.reports_count)
            const tempZeroReportCustomers = sortedCustomers.filter(
              item => item?.reports_count === 0
            )
            const tempNonZeroReportCustomers = sortedCustomers.filter(
              item => item?.reports_count > 0
            )
            if (tempNonZeroReportCustomers.length || tempZeroReportCustomers.length) {
              setShowRightMenu(true)
            }
            setCustomers(tempNonZeroReportCustomers)
            setZeroReportCustomers(pre => [...pre, ...tempZeroReportCustomers])
            if (!selectedUserId && setSelectedUserId) {
              setSelectedUserId(tempNonZeroReportCustomers?.[0]?.id)
            }
            if (customer?.id) {
              dispatch(updateSelectedCustomer(customer?.id))
            } else if (customerFromLocalStorage) {
              dispatch(updateSelectedCustomer(JSON.parse(customerFromLocalStorage)))
            } else if (customerInviteFromLocalStorage?.email === userDetail?.user?.email) {
              const inviteCustomerData = tempNonZeroReportCustomers?.filter(
                customer => customer.id === customerInviteFromLocalStorage.customer_id
              )
              dispatch(updateSelectedCustomer(inviteCustomerData[0]))
            } else if (tempNonZeroReportCustomers?.[0]) {
              dispatch(updateSelectedCustomer(tempNonZeroReportCustomers?.[0]))
            } else {
              setShowRightMenu(false)
              // renderCustomersList()
              setIsInviters(false)
              dispatch(updateSampleReport({ sampleReport: true }))
              localStorage.removeItem('customerslength')
            }
          } else {
            // setCustomers([])
            const notAdsUser = 'User in the cookie is not a valid Ads user.'
            if (response?.data?.messages?.includes(notAdsUser)) {
              // notify('Not a valid ads user to fetch customers!', 'error')
              // notify(t('notification.customerDataError'), 'error'
            } else if (response.status >= 500) {
              // notify('Internal server error!', 'error')
            } else {
              notify(t('notification.customerDataError'), 'error')
            }
          }
        }
      })
      .catch(e => {
        console.log('error msg', e)
        setIsLoading(false)
        setIsCustomers(true)
      })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isGenerateReport) {
      fetchCustomersList()
        .then(response => {
          if (response.status === 200 && response.data?.length) {
            const sortedCustomers = response.data.sort((a, b) => b.reports_count - a.reports_count)
            const tempZeroReportCustomers = sortedCustomers.filter(
              item => item?.reports_count === 0 && !!item.descriptive_name
            )
            const tempNonZeroReportCustomers = sortedCustomers.filter(
              item => item?.reports_count > 0 && !!item.descriptive_name
            )
            setCustomers(tempNonZeroReportCustomers)
            setZeroReportCustomers(pre => [...tempZeroReportCustomers])
            if (!selectedUserId && setSelectedUserId) {
              setSelectedUserId(tempNonZeroReportCustomers?.[0]?.id)
            }
          }
        })
        .catch(e => {
          // console.log(e.response)
        })
    }
    // eslint-disable-next-line
  }, [isGenerateReport])

  const updateCustomerData = (customer, key) => {
    dispatch(singleReport(''))
    dispatch(reportIndex(''))
    dispatch(updateSelectedCustomer(customer))
    setSelectedUserId(key)
    handleSelectUser(key)
  }

  const onClick = ({ key }) => {
    if (!openPopUp) {
      let selectedCustomer = customers.find(obj => obj.id === +key)
      if (selectedCustomer?.id) {
        updateCustomerData(selectedCustomer, +key)
        return
      }
      if (!selectedCustomer?.id) {
        selectedCustomer = zeroReportCustomers.find(obj => obj.id === +key)
        if (selectedCustomer?.id) {
          updateCustomerData(selectedCustomer, +key)
          return
        }
      }
      if (!selectedCustomer?.id) {
        selectedCustomer = inviters.find(obj => obj.id === +key)
        if (selectedCustomer?.id) {
          updateCustomerData(selectedCustomer, +key)
          return
        }
      }
      selectedCustomer = zeroReportInviters.find(obj => obj.id === +key)
      if (selectedCustomer?.id) {
        updateCustomerData(selectedCustomer, +key)
      }
    }
  }

  const handleGenerateReport = (e, customer, customerType) => {
    e.stopPropagation()
    const customerId = String(customer?.id)
    const parentCustomerId = getParentCustomerId(customer.resource_name)
    updateGenerateLoading(customer?.id)
    generateReportForCustomer(customerId, parentCustomerId).then(res => {
      updateGenerateLoading(customer?.id)
      if (res?.status >= 500) {
        notify(t('notification.noEnoughReportData'), 'error')
      } else if (res?.status === 201) {
        notify(t('notification.reportGenerated'), 'success')
        setReports(pre => [res?.data, ...pre])
        if (customerType === 'owner') {
          setCustomers(pre => {
            const tempCustomer = { ...customer }
            // eslint-disable-next-line
            tempCustomer.reports_count = tempCustomer.reports_count + 1
            return [...pre, tempCustomer]
          })
          setZeroReportCustomers(pre => {
            const tempCustomer = { ...customer }
            // eslint-disable-next-line
            tempCustomer.reports_count = tempCustomer.reports_count + 1
            const customerIndex = pre.findIndex(item => item.id === customer.id)
            pre.splice(customerIndex, 1)
            return [...pre]
          })
        }
      } else {
        notify(t('notification.noEnoughReportData'), 'error')
      }
    })
  }

  const generateReportPopover = (customer, customerType) => (
    <div className={styles.generateContent}>
      <p>{t('generateReportTitle2')}</p>
      <div className={styles.generateActions}>
        <Button
          size="small"
          type="primary"
          onClick={e => {
            e.stopPropagation()
            setOpenPopUP(false)
            updateGenerateReportPopover(customer?.id)
          }}
        >
          {t('noText')}
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={e => {
            e.stopPropagation()
            setOpenPopUP(false)
            updateGenerateReportPopover(customer?.id)
            handleGenerateReport(e, customer, customerType)
          }}
        >
          {t('yesText')}
        </Button>
      </div>
    </div>
  )

  const renderLabel = (customer, customerType) => (
    <div className={styles.customerLabel}>
      <p className={styles.name}>
        {customer?.descriptive_name ? customer.descriptive_name : customer?.id}
      </p>
      <div className={styles.labelAction}>
        {customer?.reports_count !== 0 && <p>{customer?.reports_count}</p>}
        {customerType === 'owner' && (
          <>
            {generateReportLoading?.[customer?.id] ? (
              <Spin size="small" className={styles.loading} />
            ) : (
              <Popover
                content={() => generateReportPopover(customer, customerType)}
                title={t('generateReportTitle1')}
                trigger="click"
                // open={!!openGenerateReportPopover?.[customer?.id]}
                open={!!isOpen?.[customer?.id]}
                placement="bottom"
                // onOpenChange={() => updateGenerateReportPopover(customer?.id)}
              >
                <PlusOutlined
                  className={styles.icon}
                  // onClick={e => handleGenerateReport(e, customer)}
                  onClick={e => {
                    e.stopPropagation()
                    setOpenPopUP(true)
                    updateGenerateReportPopover(customer?.id)
                  }}
                />
              </Popover>
            )}
          </>
        )}
      </div>
    </div>
  )

  const handlePopUp = () => {
    setIsOpen(false)
    setOpenPopUP(false)
  }
  const renderCustomersList = () => {
    let customersHTML = []
    const divider = { label: <hr />, key: null, type: 'group', children: null }
    const dividerWithTitle = {
      label: (
        <div>
          <p>{t('sharedText')}</p>
        </div>
      ),
      key: null,
      type: 'group',
      children: null,
    }
    if (customers?.length) {
      customersHTML = [...generateCustomersHTML(customers, c => renderLabel(c))]
    }
    if (zeroReportCustomers?.length) {
      if (customers?.length) {
        customersHTML.push(divider)
      }
      customersHTML = [
        ...customersHTML,
        ...generateCustomersHTML(zeroReportCustomers, c => renderLabel(c, 'owner')),
      ]
    }
    if (inviters?.length) {
      customersHTML = [
        ...customersHTML,
        dividerWithTitle,
        ...generateCustomersHTML(inviters, c => renderLabel(c)),
      ]
    }
    if (zeroReportInviters?.length) {
      if (inviters?.length) {
        customersHTML.push(divider)
      }
      customersHTML = [
        ...customersHTML,
        ...generateCustomersHTML(zeroReportInviters, c => renderLabel(c)),
      ]
    }
    return customersHTML
  }
  useEffect(() => {
    if (isInviters && isCustomers && !isLoading) {
      if (renderCustomersList().length > 0) {
        dispatch(updateSampleReport({ sampleReport: false }))
      } else {
        dispatch(updateSampleReport({ sampleReport: true }))
      }
    }
    // eslint-disable-next-line
  }, [renderCustomersList()])
  const selectedAccountReport = customers.filter(option => option.id === selectedUserId)
  let mValue = ''
  if (settingPage && selectedAccountReport.length === 0) {
    mValue = { value: null, label: t('settings.manageUsers.selectPlaceholder') }
  } else if (selectedUserId || (settingPage && selectedAccountReport.length !== 0)) {
    mValue = selectedUserId
  } else {
    mValue = { value: null, label: t('settings.manageUsers.selectPlaceholder') }
  }
  useEffect(() => {
    if (customers.length && zeroReportCustomers.length) {
      localStorage.setItem('isCustomers', JSON.stringify(true))
    } else {
      localStorage.setItem('isCustomers', JSON.stringify(false))
    }
  }, [customers, zeroReportCustomers])
  useEffect(() => {
    if (
      customers?.length ||
      zeroReportCustomers?.length ||
      inviters?.length ||
      zeroReportInviters?.length
    ) {
      localStorage.setItem('showQuestionnaire', JSON.stringify(true))
    } else {
      localStorage.setItem('showQuestionnaire', JSON.stringify(false))
    }
  }, [customers, zeroReportCustomers, zeroReportInviters, inviters])
  return !isLoading ? (
    <>
      {!rightMenu && (
        <Select
          disabled={disabled}
          size="small"
          passCustomKey={(_, index) => index}
          options={customers}
          onSelect={handleSelectUser}
          //   eslint-disable-next-line
          value={
            mValue
            //   : selectedUserId
            //   ? selectedUserId
            //   : { value: null, label: 'Please Select' }
          }
          className={styles.userTypeSelect}
          //   eslint-disable-next-line
          startAdornment={option => {
            return option.descriptive_name
              ? option.descriptive_name?.[0]
              : option.id.toString().charAt(0)
          }}
          {...selectProps}
        />
      )}
      {rightMenu && showRightMenu && (
        <Menu
          onClick={onClick}
          onMouseLeave={() => handlePopUp()}
          mode="vertical"
          id="customerSubMenu"
          className={styles.menuItem}
          items={[
            {
              label: (
                <div className={styles.menuLabel}>
                  {customer?.descriptive_name[0] && (
                    <p className={styles.startAdornment}>{customer?.descriptive_name[0]}</p>
                  )}
                  <p className={styles.overflowThreeDots}>
                    {customer?.descriptive_name ? customer?.descriptive_name : customer?.id}
                  </p>
                </div>
              ),
              selectedkeys: [customer?.id],
              children: renderCustomersList(),
            },
          ]}
        />
      )}
      {rightMenu && !showRightMenu && (
        <Menu
          mode="vertical"
          id="customerSubMenu"
          className={styles.menuItem}
          items={[
            {
              key: 'Sample Report',
              label: (
                <div className={styles.menuLabel}>
                  <p className={styles.startAdornment}>S</p>
                  <p className={styles.overflowThreeDots}>Sample Report</p>
                </div>
              ),
            },
          ]}
          disabled
        />
      )}
    </>
  ) : (
    <div className={styles.loadingContainer}>
      <Spin />
    </div>
  )
}

export default CustomersSelect
