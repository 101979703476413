import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import { Select, Checkbox, Spin } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { BOARDS_TAG_FILTER } from '@utils/constants'
import styles from './boardList.module.css'

const BoardsDetailsNav = ({ data }) => {
  const dispatch = useDispatch()
  const [originalData, setOriginalData] = useState(data?.ads || [])
  const [filteredData, setFilteredData] = useState(originalData)
  const [loading, setLoading] = useState(false)
  const [isRotated, setIsRotated] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [filterApplied, setFilterApplied] = useState(false)
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [uniqueTags, setUniqueTags] = useState([])
  // const data = useSelector(state => state?.discoverAds?.allAdsData)
  const uniqueTagss = useSelector(state => state?.discoverAds?.uniqueTags)
  useEffect(() => {
    dispatch({ type: BOARDS_TAG_FILTER, payload: filteredData })
  }, [filteredData, dispatch])

  // Collect unique tags from data
  useEffect(() => {
    const tagsSet = new Set()
    data?.ads.forEach(item => {
      if (item.tags && Array.isArray(item.tags)) {
        item.tags.forEach(tag => {
          tagsSet.add(tag)
        })
      }
    })
    setUniqueTags([...tagsSet])
    setOriginalData(data?.ads || []) // Update originalData when data changes
    setFilteredData(data?.ads || []) // Ensure filtered data is reset when data changes
  }, [data])

  const filterClick = () => {
    setIsFilterActive(!isFilterActive)
    setIsRotated(!isRotated)
  }

  const handleTagChange = values => {
    setSelectedTags(values)
    setFilterApplied(values.length > 0)

    // Filter ads based on selected tags
    const filteredAds =
      values.length > 0
        ? originalData.filter(item => item.tags && item.tags.some(tag => values.includes(tag)))
        : originalData

    setFilteredData(filteredAds)
  }

  const handleClearFilter = () => {
    setSelectedTags([])
    setFilterApplied(false)
    setFilteredData(originalData) // Reset to show all ads
  }

  return (
    <div className={styles.container}>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 150 }}>
          <Spin />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {/* Board Name and Count */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Text size={22} isBold style={{ color: '#F16434' }}>
                {data.board_name}
              </Text>
              <div className={styles.counterContainer}>
                <Text size={14} isBold>
                  {' '}
                  {filteredData.length}
                </Text>
              </div>
            </div>

            {/* Filter and Sorting by Tags */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                placeholder="Sort by : Tags"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  width: '200px',
                  maxHeight: 44,
                  marginLeft: '16px',
                  borderRadius: '2px',
                  color: '#202020',
                }}
                className="group-by-select"
                getPopupContainer={() => document.getElementById('area')}
                dropdownClassName="group-by-dropdown"
                value={selectedTags}
                onChange={handleTagChange}
                open={isFilterActive}
                onDropdownVisibleChange={filterClick}
                dropdownRender={menu => (
                  <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {' '}
                    {/* Add fixed height and scroll */}
                    {uniqueTags.length === 0 ? (
                      <Text style={{ padding: '8px 12px', color: '#C7C7C7', textAlign: 'center' }}>
                        No tags added yet
                      </Text>
                    ) : (
                      uniqueTags.map(tag => (
                        // eslint-disable-next-line
                        <div
                          key={tag}
                          style={{ padding: '8px 12px', cursor: 'pointer' }}
                          onClick={e => e.stopPropagation()} // Prevents div click from triggering checkbox click
                        >
                          <Checkbox
                            checked={selectedTags.includes(tag)}
                            onChange={() => {
                              const isChecked = selectedTags.includes(tag)
                              const newSelectedTags = isChecked
                                ? selectedTags.filter(t => t !== tag)
                                : [...selectedTags, tag]
                              handleTagChange(newSelectedTags)
                            }}
                          >
                            {tag}
                          </Checkbox>
                        </div>
                      ))
                    )}
                  </div>
                )}
              />
              <DownOutlined
                style={{
                  color: '#C7C7C7',
                  marginLeft: '-30px',
                  cursor: 'pointer',
                  transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
                onClick={filterClick}
              />
              {filterApplied && (
                <Text onClick={handleClearFilter} className={styles.clearTag}>
                  Clear
                </Text>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BoardsDetailsNav
