import React, { useState } from 'react'
import Text from '@components/Text/text'
import styles from './categoryButon.module.css'

const CategoryButton = ({ categryData, onClick, clearState }) => {
  const [clickedNames, setClickedNames] = useState([])

  const handleClick = () => {
    const pageName = categryData?.additional_info?.page_name

    if (pageName && !clickedNames.includes(pageName)) {
      onClick(pageName)
      setClickedNames(prevNames => [...prevNames, pageName])
    } else {
      // console.log('Name already clicked or does not exist:', pageName)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <button
          onClick={handleClick}
          type="button"
          className={styles.button}
          style={{ display: 'flex' }}
        >
          {categryData?.additional_info?.page_profile_picture_url ? (
            <img
              src={categryData?.additional_info?.page_profile_picture_url}
              alt="category-logo"
              width={24}
              height={24}
              style={{ borderRadius: '24px' }}
            />
          ) : (
            ''
          )}

          <span
            style={{
              paddingLeft: 8,
              whiteSpace: 'nowrap',
            }}
          >
            {categryData?.additional_info?.page_name}
          </span>
        </button>
      </div>
    </div>
  )
}

export default CategoryButton
