import React, { useState, useEffect } from 'react'
import { Input, Checkbox, Button, message, Modal, Select } from 'antd'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { RequestService } from '@services/requests'
import { API_DATA_BOARDS_LIST } from '@utils/constants'
import { addBoard } from '../../../store/actions'

const { Option } = Select

const PinToBoard = ({ isPinModalOpen, adsData, handleCancel }) => {
  const dispatch = useDispatch()
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [boardsList, setBoardsList] = useState(apiDataBoardsList)
  const [selectedBoards, setSelectedBoards] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [disabledBoards, setDisabledBoards] = useState([])

  // Fetch boards from the server
  const handleDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setBoardsList(response?.data)
    } catch (error) {
      // console.log('Error fetching boards:', error)
    }
  }

  useEffect(() => {
    handleDiscoverAdsBoard()
    // eslint-disable-next-line
  }, [])

  // Handle selecting and deselecting boards
  const handleBoardSelection = value => {
    setSelectedBoards(value)
  }

  // Create a new board if it doesn't exist
  const handleCreateBoard = async () => {
    const trimmedValue = searchTerm.trim()
    if (trimmedValue) {
      const existingBoard = boardsList.find(
        board => board?.name?.toLowerCase() === trimmedValue.toLowerCase()
      )

      if (!existingBoard) {
        try {
          const response = await RequestService.post('/create/boards/', { name: trimmedValue })
          const createdBoard = response.data
          const updatedBoards = [...boardsList, createdBoard]
          setBoardsList(updatedBoards)
          setSelectedBoards([...selectedBoards, createdBoard.name])
          dispatch(addBoard(createdBoard))
          message.success('Board created and selected!')
        } catch (error) {
          console.error('Error creating board:', error)
        }
      } else {
        message.info('Board already exists.')
      }
    }
    setSearchTerm('') // Reset the search term after creating the board
  }

  // Handle adding ads to selected boards
  const handlePinToBoards = async () => {
    const selectedBoardIds = boardsList
      .filter(board => selectedBoards.includes(board.name))
      .map(board => board.id)

    try {
      await Promise.all(
        selectedBoardIds.map(boardId =>
          RequestService.post('/create/pinned-ads/', {
            // eslint-disable-next-line
            ad_id: adsData._id,
            board: boardId,
          })
        )
      )
      setDisabledBoards([...disabledBoards, ...selectedBoards])
      message.success('Ad pinned to selected boards!')
    } catch (error) {
      console.error('Error pinning ad to boards:', error)
    }
  }

  // Disable boards where is_pinned is true
  useEffect(() => {
    const pinnedBoards = boardsList.filter(board => board.is_pinned).map(board => board.name)
    setDisabledBoards(pinnedBoards)
  }, [boardsList])

  return (
    <Modal
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Pin Ad to Boards</span>
          <CloseOutlined onClick={handleCancel} style={{ cursor: 'pointer' }} />
        </div>
      }
      visible={isPinModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <div style={{ marginBottom: 12 }}>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select or create a board"
          value={selectedBoards}
          onChange={handleBoardSelection}
          // onSearch={setSearchTerm}
          dropdownRender={menu => (
            <div>
              {/* Create board input at the top */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 8,
                  borderBottom: '1px solid #f0f0f0',
                  backgroundColor: '#fafafa',
                }}
              >
                <Input
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  placeholder="Create a board"
                  style={{ marginRight: 8 }}
                  onPressEnter={handleCreateBoard}
                />
                <Button
                  type="link"
                  onClick={handlePinToBoards}
                  icon={<PlusOutlined />}
                  // disabled={!searchTerm.trim()}
                >
                  Add
                </Button>
              </div>

              {/* Existing board list */}
              {menu}
            </div>
          )}
        >
          {' '}
          {boardsList.map(board => {
            // eslint-disable-next-line
            const isPinned = board.pinned_ads?.some(ad => ad.ad_id === adsData?._id) // Check if the ad is pinned to this board
            const isSelected = selectedBoards.includes(board.name) // Check if the board is selected

            return (
              <Select.Option
                key={board.id}
                value={board.name}
                disabled={isPinned} // Disable the option if the ad is pinned
                style={{
                  cursor: isPinned ? 'not-allowed' : 'pointer', // Show 'not-allowed' cursor if pinned
                }}
              >
                <Checkbox
                  checked={isPinned || isSelected} // Auto-check if the board is pinned or selected
                  disabled={isPinned} // Disable checkbox if pinned
                >
                  {board.name}
                </Checkbox>
              </Select.Option>
            )
          })}
        </Select>
      </div>
    </Modal>
  )
}

export default PinToBoard
