import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import AdsCard from '@components/DiscoverAds/AdsCard'
import { RequestService } from '@services/requests'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useSelector } from 'react-redux'

const BoardsDetailsSection = () => {
  const boardsList = useSelector(state => state?.discoverAds?.boardList)
  const boardsTagsFilter = useSelector(state => state?.discoverAds?.boardsTagsFilter)
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    if (boardsTagsFilter?.length > 0) {
      const sortedData = boardsTagsFilter.sort((a, b) => b.is_pinned - a.is_pinned)
      setFilteredData(sortedData)
    }
  }, [boardsTagsFilter])

  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleShowModal = index => {
    setSelectedIndex(index)
  }

  const handlePrevious = () => {
    setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : filteredData.length - 1))
  }

  const handleNext = () => {
    setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : 0))
  }
  return (
    <>
      {filteredData.length <= 0 ? (
        <Text size={24} isBold style={{ marginTop: 40, textAlign: 'center' }}>
          No Boards Found
        </Text>
      ) : (
        <>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
            <Masonry columnsCount={3} gutter="20px">
              {filteredData?.map((item, index) => (
                <AdsCard
                  adsData={item}
                  onShowModal={() => handleShowModal(index)}
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  selectedData={filteredData[selectedIndex]}
                  isFirst={selectedIndex === 0}
                  isLast={selectedIndex === filteredData.length - 1}
                  boardsList={boardsList}
                  isAdPinned={item?.is_pinned}
                  boardDetailRoute="true"
                  savedAdsFlow="true"
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </>
      )}
    </>
  )
}
export default BoardsDetailsSection
